import { useEffect } from 'react';

export function useTripleWhalePageLoad({ DEBUG }) {
  useEffect(() => {
    if (typeof window === 'undefined' || !window.TriplePixel) return;

    // add pubsub for path changed
    const pathChanged = PubSub.subscribe('PATH_CHANGED', async (event, data) => {
      if (data.path.includes('/products/')) {
        return;
      }

      window.TriplePixel('pageLoad');
    });

    const viewProduct = PubSub.subscribe('VIEW_PRODUCT_PAGE', async (event, variant) => {

      window.TriplePixel('pageLoad', {
        product: {
          id: variant.product.id.replace('gid://shopify/Product/', ''),
          name: variant.product.title,
          price: variant.priceV2?.amount,
          variant: variant.id.replace('gid://shopify/ProductVariant/', '')
        }
      });

      if (DEBUG) console.log('TripleWhale:pageLoad:product', variant);
    });

    // const viewSearchResults = PubSub.subscribe('VIEW_SEARCH_RESULTS', async (event, searchTerm) => {
    //   window.TriplePixel('pageLoad', {
    //     search: searchTerm
    //   });
      
    //   if (DEBUG) console.log('TripleWhale:pageLoad:search', { searchTerm });
    // });

    // const clickSearchItem = PubSub.subscribe('CLICK_SEARCH_ITEM', async (event, data) => {
    //   window.TriplePixel('pageLoad', {
    //     search: data
    //   });

    //   if (DEBUG) console.log('TripleWhale:pageLoad:search', { data });
    // });

    return () => {
      if (viewProduct) PubSub.unsubscribe(viewProduct);
    //   if (viewSearchResults) PubSub.unsubscribe(viewSearchResults);
    //   if (clickSearchItem) PubSub.unsubscribe(clickSearchItem);
      if (pathChanged) PubSub.unsubscribe(pathChanged);
    };
  }, []);
} 